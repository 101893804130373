<script lang="ts">
    import { showPoints } from '@/stores/subscription';
    import PointLight from '@/Icons/PointLight.svelte';
    import { onMount } from 'svelte';

    export let pointsPriceDeposit: number;
    export let monthlyPrice: number = 0;
    export let threeMonthPrice: number | undefined = undefined;
    export let sixMonthPrice: number | undefined = undefined;
    export let twelveMonthPrice: number | undefined = undefined;

    const pointWhite = `<svg width='27' height='28' viewBox='0 0 11 12' fill='none' xmlns='http://www.w3.org/2000/svg'><g clip-path='url(#clip0_1_47)'><path d='M5.5 11.3333C8.53757 11.3333 11 8.87089 11 5.83333C11 2.79576 8.53757 0.333328 5.5 0.333328C2.46243 0.333328 0 2.79576 0 5.83333C0 8.87089 2.46243 11.3333 5.5 11.3333Z' fill='white'/><path d='M8.10935 5.02533C8.10935 5.35066 8.03468 5.64933 7.88535 5.92133C7.73601 6.188 7.50668 6.404 7.19735 6.56933C6.88801 6.73466 6.50401 6.81733 6.04535 6.81733H5.19735V8.83333H3.82935V3.21733H6.04535C6.49335 3.21733 6.87201 3.29466 7.18135 3.44933C7.49068 3.604 7.72268 3.81733 7.87735 4.08933C8.03201 4.36133 8.10935 4.67333 8.10935 5.02533ZM5.94135 5.72933C6.20268 5.72933 6.39735 5.668 6.52535 5.54533C6.65335 5.42266 6.71735 5.24933 6.71735 5.02533C6.71735 4.80133 6.65335 4.628 6.52535 4.50533C6.39735 4.38266 6.20268 4.32133 5.94135 4.32133H5.19735V5.72933H5.94135Z' fill='#6366F1'/></g></svg>`;

    const pointColored = `<svg width='27' height='28' viewBox='0 0 11 12' fill='none' xmlns='http://www.w3.org/2000/svg'><g clip-path='url(#clip0_1_38)'><path d='M5.5 11.3333C8.53757 11.3333 11 8.87089 11 5.83333C11 2.79576 8.53757 0.333328 5.5 0.333328C2.46243 0.333328 0 2.79576 0 5.83333C0 8.87089 2.46243 11.3333 5.5 11.3333Z' fill='#6366F1'/><path d='M8.10935 5.02533C8.10935 5.35066 8.03468 5.64933 7.88535 5.92133C7.73601 6.188 7.50668 6.404 7.19735 6.56933C6.88801 6.73466 6.50401 6.81733 6.04535 6.81733H5.19735V8.83333H3.82935V3.21733H6.04535C6.49335 3.21733 6.87201 3.29466 7.18135 3.44933C7.49068 3.604 7.72268 3.81733 7.87735 4.08933C8.03201 4.36133 8.10935 4.67333 8.10935 5.02533ZM5.94135 5.72933C6.20268 5.72933 6.39735 5.668 6.52535 5.54533C6.65335 5.42266 6.71735 5.24933 6.71735 5.02533C6.71735 4.80133 6.65335 4.628 6.52535 4.50533C6.39735 4.38266 6.20268 4.32133 5.94135 4.32133H5.19735V5.72933H5.94135Z' fill='white'/></g></svg>`;

    function handleToggle() {
        showPoints.update((value) => !value);
    }

    onMount(() => {
        showPoints.set(true);
        updatePriceDisplays();
    });

    function updatePriceDisplays() {
        const showCurrency = $showPoints;
        const pointsDeposit = pointsPriceDeposit || 1;

        const updateElement = (selector: string, price: number | undefined, icon: string) => {
            const element = document.querySelector(selector);
            if (!element) return;

            const safePrice = price || 0;
            element.innerHTML = showCurrency
                ? `$${safePrice}`
                : `<span class='flex items-center'>${icon}&nbsp;${(safePrice / pointsDeposit).toFixed(0)}</span>`;
        };

        updateElement('.subscription-price-value-30', monthlyPrice, pointWhite);
        updateElement('.subscription-price-value-90', threeMonthPrice, pointColored);
        updateElement('.subscription-price-value-182', sixMonthPrice, pointColored);
        updateElement('.subscription-price-value-365', twelveMonthPrice, pointColored);
    }

    $: $showPoints, updatePriceDisplays();
</script>

<div class="flex items-center">
    <PointLight size="size-5" />
    &nbsp;
    <label class="switch mb-0">
        <input class="switch__input" type="checkbox" checked="{$showPoints}" on:change="{handleToggle}" />
        <span class="switch__slider"></span>
    </label>
    &nbsp;
    <span class="font-mona-expanded text-lg font-bold text-black dark:!text-white">$</span>
</div>
